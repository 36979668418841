import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=02ccd6f2&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\e-trailor\\Bont\\Frontend\\BontScheduler.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02ccd6f2')) {
      api.createRecord('02ccd6f2', component.options)
    } else {
      api.reload('02ccd6f2', component.options)
    }
    module.hot.accept("./Button.vue?vue&type=template&id=02ccd6f2&", function () {
      api.rerender('02ccd6f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/common/inputs/togglebutton/Button.vue"
export default component.exports